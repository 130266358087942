import { useRouter } from 'next/router';

import { Typography } from '@core/UI/Typography';
import * as UI from '@core/components/NotFound';
import Template from '@core/components/Template';
import { withAuthorization } from '@core/services/Auth/withAuthorization';
import { wrapper } from '@core/store';

interface INotFoundPageProps {
  isAuthenticated: boolean;
}

const NotFoundPage = (props: INotFoundPageProps) => {
  const { isAuthenticated } = props;

  const router = useRouter();

  return (
    <Template hasPolls={false} isPublic={!isAuthenticated}>
      <UI.Layout>
        <UI.Title tag="h1">Ошибка 404</UI.Title>
        <Typography tag="p" type="h2" weight="normal">К сожалению, запрашиваемая страница не найдена</Typography>
        <UI.BackButton onClick={router.back}>Назад</UI.BackButton>
      </UI.Layout>
    </Template>
  );
};

export const getServerSideProps = wrapper.getServerSideProps(withAuthorization(({ getState }) => async () => (
  { props: { isAuthenticated: getState().authentication.data.isAuthChecked } }
)));

export default NotFoundPage;
